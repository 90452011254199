$(function(){
    $('.select2').select2({
        allowClear: true,
        placeholder: SelectText
    });

    window.dropdownSubjectsOptions = {
        ajax: {
            url: BaseUrl + "/api/select2/subjects",
            dataType: 'json',
            data: function (params) {
                department = $('#has-curriculum').val();
                return {
                    query: params,
                    department: department
                };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/subject/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    };    
    $(".dropdown-subjects").select2(dropdownSubjectsOptions);

    $('#has-curriculum').change(function(){
        $('#belongs-to-curriculum').val('').trigger("change");
    });

    $(".dropdown-departments").select2({
        ajax: {
            url: BaseUrl + "/api/select2/departments",
            dataType: 'json',
            data: function (params) {
                $('#belongs-to-curriculum').val('').trigger("change");
                return {
                    query: params
                };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/department/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    });

    window.dropdownSemestersOptions = {
        ajax: {
            url: BaseUrl + "/api/select2/semesters",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/semester/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText,
        minimumInputLength: 2
    };
    $(".dropdown-semesters").select2(dropdownSemestersOptions);

    window.dropdownAllTeachersOptions = {
        ajax: {
            url: BaseUrl + "/api/select2/allTeachers",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/teacher/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    };
    $(".dropdown-all-teachers").select2(dropdownAllTeachersOptions);

    $(".dropdown-teachers").select2({
        ajax: {
            url: BaseUrl + "/api/select2/teachers",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/teacher/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    });

    $(".dropdown-contractual-teachers").select2({
        ajax: {
            url: BaseUrl + "/api/select2/contractualTeachers",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/teacher/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    });    

    window.dropdownAllUsers = {
        ajax: {
            url: BaseUrl + "/api/select2/allUsers",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/user/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true,
        placeholder: SelectText
    };

    $(".dropdown-all-users").select2(dropdownAllUsers);

    $(".dropdown-employees").select2({
        ajax: {
            url: BaseUrl + "/api/select2/employees",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/employee/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    });

    $(".dropdown-all-students").select2({
        ajax: {
            url: BaseUrl + "/api/select2/allStudents",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/student/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: SelectText
    });

    $('#buildings').change(function () {
        $('div.loader').show();
        building = $('#buildings option:selected').val();
        $.ajax({
            type:'GET',
            url:BaseUrl + '/api/select2/rooms/' + building,
            dataType: 'html',
            success:function(data){     
                var data = $.parseJSON(data);
                rooms = $("#rooms");
                rooms.html('<option selected="selected" value="">'+ window.SelectText +'</option>');
                selected = rooms.attr('data-value');                
                $.each(data, function (index, value) {                   
                    rooms.append('<option value="'+index+'">'+value+'</option>');
                }); 
                $('div.loader').hide();
                rooms.change();
            }
        });
    });

    $('#dormitory-list').change(function () {
        $('div.loader').show();
        list = $('#dormitory-list option:selected').val();
        $.ajax({
            type:'GET',
            url:BaseUrl + '/api/select2/dormitoryStudents/' + list,
            dataType: 'html',
            success:function(data){     
                var data = $.parseJSON(data);
                $("#dormitory-students").html('<option selected="selected" value="">'+ SelectText +'</option>');
                selected = $("#dormitory-students").attr('old');
                $.each(data, function (index, value) {
                    if (selected == index)          
                        $("#dormitory-students").append('<option selected value="'+index+'">'+value+'</option>');
                    else
                        $("#dormitory-students").append('<option value="'+index+'">'+value+'</option>');
                }); 
                $('div.loader').hide();
                $("#dormitory-students").change()
            }
        });
    });   

    $('#transport-list').change(function () {
        $('div.loader').show();
        list = $('#transport-list option:selected').val();
        $.ajax({
            type:'GET',
            url:BaseUrl + '/api/select2/transportStudents/' + list,
            dataType: 'html',
            success:function(data){     
                var data = $.parseJSON(data);
                $("#transport-students").html('<option selected="selected" value="">'+ SelectText +'</option>');
                selected = $("#transport-students").attr('old');
                $.each(data, function (index, value) {
                    if (selected == index)          
                        $("#transport-students").append('<option selected value="'+index+'">'+value+'</option>');
                    else
                        $("#transport-students").append('<option value="'+index+'">'+value+'</option>');
                }); 
                $('div.loader').hide();
                $("#transport-students").change()
            }
        });
    });  

    window.studentCategoriesOptions = {
        ajax: {
            url: BaseUrl + "/api/select2/studentCategories",
            dataType: 'json',
            data: function (params) {
              return {
                query: params
              };
            },
            results: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        initSelection: function(element, callback) {            
            var id = $(element).val();
            if (id !== "") {
                $.ajax(BaseUrl + "/api/select2/studentCategory/" + id, {
                    dataType: "json"
                }).done(function(data) { callback(data); });
            }
        },
        minimumInputLength: 1,
        allowClear: true, 
        placeholder: window.SelectText
    }      

    $(".dropdown-student-categories").select2(studentCategoriesOptions);
})

$(document).ajaxError(function(event, jqxhr, settings, exception) {
    if (exception == 'Unauthorized') {
        // Prompt user if they'd like to be redirected to the login page        
        swal( 
            'خطا!',
            'وقت شما تمام شده است! لطفا مجددا به سیستم وارد شوید.',            
            'error'
        ).then(function () {
            window.location = window.LoginPage;
        })
    }
});

// disable datatables error prompt
$.fn.dataTable.ext.errMode = 'none';
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$('form:not(.no-waiting)').submit(function () {
    $('button[type="submit"]', this).text('لطفا صبر کنید...')
    $('button[type="submit"]', this).prop("disabled", "disabled");
})